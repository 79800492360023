import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-bulle',
  templateUrl: './info-bulle.component.html',
  styleUrls: ['./info-bulle.component.scss'],
})
export class InfoBulleComponent implements OnInit {
  @Input('infos') infos;

  constructor(private translate: TranslateService) {}

  ngOnInit() {}
}
