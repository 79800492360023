import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-abreviation-bulle',
  templateUrl: './abreviation-bulle.component.html',
  styleUrls: ['./abreviation-bulle.component.scss'],
})
export class AbreviationBulleComponent implements OnInit {

  @Input("full") full;

  constructor() { }

  ngOnInit() {}

}
